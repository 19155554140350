.App {
  margin: 0;
  padding: 0;
}

.container {
  height: 100%;
  display: flex;
}

.content-area {
  padding: 40px;
  flex: 1;
}

.card-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.card {
  border: 1px solid #000;
  margin: 0 10px 20px 10px;
  min-width: 25%;
  text-align: center;
}

.card-large-outside {
  border: 1px solid #000;
  margin: 0 10px 20px 10px;
  min-width: 45%;
  text-align: center;
}

.card-large {
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-evenly;
}

.card-top-large {
  padding: 10px;
}

.card-top {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.card-info {
  text-align: center;
}

.card-info p {
  padding: 0;
  margin: 0;
  font-size: 0.75rem;
  color: #646569;
}

.card-info h5 {
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
}

.card-footer {
  text-align: center;
  padding: 5px;
  font-size: 0.875rem;
  background-color: #f8f8f8;
}

.card-footer-button {
  text-align: center;
  border: none;
  padding: 1px;
  background-color: transparent;
  color: #0099ff;
  cursor: pointer;
}

.graph-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: no-wrap;
}

.left-graph,
.right-graph {
  border: 1px solid #000;
  margin: 0 10px 20px 10px;
  padding: 20px;
}

.graph-top {
  text-align: center;
  padding: 10px;
}

.left-graph img,
.right-graph img {
  width: 100%;
  height: auto;
}

.left-graph-chart,
.right-graph-chart {
  width: 100%;
  height: auto;
}

.card-title {
  font-size: 1.5rem;
  padding-top: 20px;
  margin-bottom: 20px;
}

.user-details-card {
  box-sizing: border-box;
  width: 350px;
  padding-left: 40px;
  padding-right: 40px;
  border-right: 1px solid #c9c9c9;
}

.user-details-title {
  font-size: 1.25rem;
}

.breadcrumbs p {
  color: #C9C9C9;
}

.breadcrumbs button {
  margin-right: 0px;
  text-transform: none;
  color: #0099FF;
  text-decoration: none;
  padding: 0px;
  font-family: 'TTLakes-Medium';
}

.page-title {
  font-size: 2rem;
  padding-top: 20px;
  margin-bottom: 20px;
}
