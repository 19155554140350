.center {
  padding: 40px;
  margin: 0 auto;
}

.user-details {
  display: flex;
  margin: 20px 0;
}

.user-details-card:first-of-type {
  padding-left: 0px;
}

.user-details-card:last-of-type {
  border-right: none;
}

.cityzip {
  display: flex;
}

.big-blue-btn {
  padding: 8px 20px;
  margin-bottom: 10px;
  background-color: #0099ff !important;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
}

.big-blue-btn svg {
  margin-right: 10px;
}

.botton-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
}

.botton-nav a {
  margin-right: 20px;
}

.botton-nav a:visited {
  text-decoration: none;
  color: #0099ff;
}

input[type='text'],
input[type='select'],
input[type='email'],
input[type='password'],
input[type='tel'],
select {
  border: 1px solid #c9c9c9;
  padding: 8px 10px;
  width: 100%;
  margin-bottom: 5px;
}

input[type='text'].input-error,
input[type='select'].input-error,
input[type='email'].input-error,
input[type='password'].input-error,
input[type='tel'].input-error,
select.input-error {
  border: 1px solid red;
  margin-bottom: 0px;
}
