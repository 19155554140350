body {
  margin: 0;
  padding: 0;
  font-family: 'TTLakes-Medium';
  font-weight: 500;
  font-size: 16px;
}

@font-face {
  font-family: 'TTLakes-Medium';
  src: url(./fonts/TypeType\ -\ TTLakes-Medium.otf);
}

* {
  box-sizing: border-box;
}
