.subscription-card,
.subscription-card-focus {
  border: 1px solid #000;
  text-align: center;
  margin: 0 0 10px 0;
  width: 100%;
}

.subscription-card-focus {
  border: 2px solid #000;
  margin-right: 0;
}

.subscription-card-top {
  display: flex;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.subscription-card-info {
  text-align: center;
  width: 100%;
  padding: 0;
}

.subscription-card-info p {
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  color: #646569;
}

.subscription-card-info h5 {
  padding: 0;
  margin: 0;
  font-size: 1.575rem;
  font-weight: 500;
}

.subscription-card-footer {
  text-align: center;
  padding: 5px;
  background-color: #f8f8f8;
}

.subscription-card-footer button {
  text-decoration: none;
  color: #0099ff;
  text-transform: capitalize;
  padding: 0px;
  font-size: 0.875rem;
}

.subscription-card-footer .select-button {
  padding-right: 20px;
}

.subscription-card-footer button:hover {
  text-decoration: underline;
}
