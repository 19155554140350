.error {
  color: red;
  font-size: 0.875rem;
  padding: 0;
  margin-bottom: 5px;
}

.error-hidden {
  visibility: hidden;
  font-size: 0.875rem;
}
