.big-blue-btn,
.red-btn,
.green-btn,
.yellow-btn,
.disabled-btn {
  padding: 8px 20px;
  margin: 0 0 10px 10px;
  background-color: #0099ff;
  color: #fff !important;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.disabled-btn {
  background-color: #c9c9c9;
  color: #646569;
  cursor: default;
}

.red-btn {
  background-color: red !important;
}

.green-btn {
  background-color: #a1cc14;
}

.yellow-btn {
  background-color: #ffdb00;
}

.big-blue-btn svg,
.red-btn svg,
.green-btn svg,
.yellow-btn svg,
.disabled-btn svg {
  margin-right: 10px;
}

.botton-nav a:visited {
  text-decoration: none;
  color: #0099ff;
}

.blue-pill-btn {
  padding: 12px 30px;
  background-color: #0099ff;
  color: #fff;
  border: none;
  border-radius: 24px;
  align-items: center;
  cursor: pointer;
}

.txtBtn {
  border: none;
  background-color: transparent;
  color: #0099ff;
  margin-right: 20px;
}

.txtBtn:hover {
  text-decoration: underline;
}
