header {
  border-bottom: 2px solid #ffdb00;
}

.navbar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  color: #000;
  width: 100%;
}

.brand-title {
  font-size: 1.5rem;
  margin: 0.5rem 0px 0.5rem 20px;
}

.navbar-links {
  height: 100%;
  padding-right: 40px;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
  display: flex;
  align-items: center;
}

.navbar-links-routing {
  display: flex;
  text-decoration: none;
  color: #000;
  padding: 1rem 0.75rem;
  align-items: center;
}

.navbar-links-routing-svg {
  margin-right: 5px;
}

.navbar-links li:hover {
  background-color: #555;
}

/* Temporary for demo */
button {
  background: none;
  border: none;
  padding: 12px;
  color: black;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1rem;
}

.user-name {
  text-transform: uppercase;
}
