.logout-button {
    color: red;
    padding: 0px;
    margin-right: 0px;
    font-size: inherit;
    text-transform: none;
    font-family: 'TTLakes-Medium';
}

.device-name {
    float: left;
    display: flex;
}

.device-name div {
    padding-left: 5px;
 }