.billing-details-card {
  text-align: center;
  width: 60%;
  margin-top: 20px;
}

.subscription-details-card {
  text-align: center;
  width: 40%;
}

.billing-container {
  width: min-content;
}