.bottom-nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
}
.bottom-nav a {
  margin-right: 20px;
}
.bottom-nav a:visited {
  text-decoration: none;
  color: #0099ff;
}

.bottom-nav button {
  margin-bottom: 10px;
}
