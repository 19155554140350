.registration-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-1,
.header-2 {
  width: 300px;
  height: 80px;
  position: relative;
  background: #ffdb00;
}

.header-1 {
  z-index: 1;
}

.header-1:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
}
.header-1:before {
  content: '';
  position: absolute;
  right: -40px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 40px solid #ffdb00;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
}

.header-2 {
  width: 360px;
  padding-left: 40px;
  padding-right: 20px;
}

.header-yellow {
  background-color: #ffdb00;
  color: #000;
}

.header-green {
  background-color: #a1cc14;
  color: #000;
}

.header-green:before {
  border-left: 40px solid #a1cc14;
}

.header-gray {
  background: #c9c9c9;
}

.header-gray p {
  color: #646569;
}

.cnt {
  position: relative;
  top: 10%;
  left: 1em;
  color: #000;
  display: flex;
  align-items: center;
}
.cnt p {
  font-size: 0.875rem;
  padding-left: 20px;
}
