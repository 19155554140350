.box-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #c9c9c9;
  box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px,
    rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
  padding: 20px;
  text-align: center;
}

.modal-title {
  margin: 0;
  font-family: 'TTLakes-Medium';
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.modal-description {
  margin: 16px 0px 0px;
  font-family: 'TTLakes-Medium';
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.modal-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
