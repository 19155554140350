.user-dashboard-card {
  float: left;
  width: 33%;
  padding-left: 40px;
  padding-right: 40px;
  border-right: 1px solid #c9c9c9;
  justify-content: space-between;
  flex-wrap: no-wrap;
}

.user-dashboard-card:last-of-type {
  border-right: none;
}

.user-dashboard-card:first-of-type {
  padding-left: 0px;
}

.user-activity-btn {
  float: right;
}

.user-info {
  display: flex;
  justify-content: space-between;
}

.user-info p {
  font-size: 1.25rem;
  padding: 0;
  margin: 0;
  line-height: 1.8rem;
}

.force-btn-group {
  display: flex;
  flex-direction: column;
}

.view-btn {
  text-transform: none;
}

.user-dash-account .view-btn {
  float:right;
  padding: 0px;
}

.two-factor {
  margin-top: 40px;
}

.two-factor h3 {
  margin-bottom: 0px;
}

.two-factor p {
  font-size: .875rem;
  margin-top: 0px;
}