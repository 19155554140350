.verification-check {
  margin: auto;
  width: 350px;
  height: 400px;
  position: relative;
  top: 80px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  padding: 20px;
  text-align: center;
}

.verification-title {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.verification-body {
  font-size: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}
