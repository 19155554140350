.sub-modal {
  width: auto;
  text-align: center;
}

.sub-modal h1 {
  text-transform: uppercase;
  color: #0099ff;
}

.sub-modal h1:after {
  border-bottom: 5px solid #ffdb00;
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  padding-top: 20px;
}

.sub-modal-nav {
  display: inline-block;
}

.sub-modal-nav .big-blue-btn {
  margin: 0px 0px 10px 0px;
}

.sub-modal-nav .txtBtn {
  margin-right: 0px;
}
