.left-nav {
  padding: 40px 0 0 0;
  width: 180px;
  background-color: #ffdb00;
  font-weight: 500;
  height: calc(100vh - 85px);
}

.left-nav ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.left-nav li {
  list-style: none;
  padding-bottom: 5px;
}

.left-nav li a {
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 5px 20px;
}

.left-nav li a svg {
  margin-right: 10px;
}

.left-nav li .active,
.left-nav li a:hover {
  background-color: #000;
  color: #ffdb00;
}
