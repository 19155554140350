.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  max-width: 50%;
}

.modal-content p,
.modal-content h2 {
  text-align: center;
}

.modal-content h2 {
  padding: 0;
  margin: 0;
}

.modal-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
